.bs-docs-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  color: #99979c;
  text-align: center;
  background-color: #2a2730;
}
.bs-docs-footer a {
  color: #fff;
}
.bs-docs-footer-links {
  padding-left: 0;
  margin-bottom: 20px;
}
.bs-docs-footer-links li {
  display: inline-block;
}
.bs-docs-footer-links li + li {
  margin-left: 15px;
}

@media (min-width: @screen-sm-min) {
  .bs-docs-footer {
    text-align: left;
  }
  .bs-docs-footer p {
    margin-bottom: 0;
  }
}
