// stylelint-disable selector-max-compound-selectors

// By default it's not affixed in mobile views, so undo that
.bs-docs-sidebar.affix {
  position: static;
}
@media (min-width: @screen-sm-min) {
  .bs-docs-sidebar {
    padding-left: 20px;
  }
}

.bs-docs-search {
  margin-bottom: 20px;
  margin-left: 20px;
}

// First level of nav
.bs-docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px;
}

// All levels of nav
.bs-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #767676;
}
.bs-docs-sidebar .nav > li > a:hover,
.bs-docs-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: #563d7c;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid #563d7c;
}
.bs-docs-sidebar .nav > .active > a,
.bs-docs-sidebar .nav > .active:hover > a,
.bs-docs-sidebar .nav > .active:focus > a {
  padding-left: 18px;
  font-weight: 700;
  color: #563d7c;
  background-color: transparent;
  border-left: 2px solid #563d7c;
}

// Nav: second level (shown on .active)
.bs-docs-sidebar .nav .nav {
  display: none; // Hide by default, but at >768px, show it
  padding-bottom: 10px;
}
.bs-docs-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 12px;
  font-weight: 400;
}
.bs-docs-sidebar .nav .nav > li > a:hover,
.bs-docs-sidebar .nav .nav > li > a:focus {
  padding-left: 29px;
}
.bs-docs-sidebar .nav .nav > .active > a,
.bs-docs-sidebar .nav .nav > .active:hover > a,
.bs-docs-sidebar .nav .nav > .active:focus > a {
  padding-left: 28px;
  font-weight: 500;
}

// Back to top (hidden on mobile)
.back-to-top,
.bs-docs-theme-toggle {
  display: none;
  padding: 4px 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
}
.back-to-top:hover,
.bs-docs-theme-toggle:hover {
  color: #563d7c;
  text-decoration: none;
}
.bs-docs-theme-toggle {
  margin-top: 0;
}

@media (min-width: @screen-sm-min) {
  .back-to-top,
  .bs-docs-theme-toggle {
    display: block;
  }
}

// Show and affix the side nav when space allows it
@media (min-width: @screen-md-min) {
  .bs-docs-sidebar .nav > .active > ul {
    display: block;
  }
  // Widen the fixed sidebar
  .bs-docs-sidebar.affix,
  .bs-docs-sidebar.affix-bottom {
    width: 213px;
  }
  .bs-docs-sidebar.affix {
    position: fixed; // Undo the static from mobile first approach
    top: 20px;
  }
  .bs-docs-sidebar.affix-bottom {
    position: absolute; // Undo the static from mobile first approach
  }
  .bs-docs-sidebar.affix-bottom .bs-docs-sidenav,
  .bs-docs-sidebar.affix .bs-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: @screen-lg-min) {
  // Widen the fixed sidebar again
  .bs-docs-sidebar.affix-bottom,
  .bs-docs-sidebar.affix {
    width: 263px;
  }
}
