.bs-docs-header {
  margin-bottom: 40px;
  font-size: 20px;
}
.bs-docs-header h1 {
  margin-top: 0;
  color: #fff;
}
.bs-docs-header p {
  margin-bottom: 0;
  font-weight: 300;
  line-height: 1.4;
}
.bs-docs-header .container {
  position: relative;
}

@media (min-width: @screen-sm-min) {
  .bs-docs-header {
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 24px;
    text-align: left;
  }
  .bs-docs-header h1 {
    font-size: 60px;
    line-height: 1;
  }
}

@media (min-width: @screen-md-min) {
  .bs-docs-header h1,
  .bs-docs-header p {
    margin-right: 380px;
  }
}
