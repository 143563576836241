.bs-glyphicons {
  margin: 0 -10px 20px;
  overflow: hidden;
}
.bs-glyphicons-list {
  padding-left: 0;
  list-style: none;
}
.bs-glyphicons li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #fff;
}
.bs-glyphicons .glyphicon {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 24px;
}
.bs-glyphicons .glyphicon-class {
  display: block;
  text-align: center;
  word-wrap: break-word; // Help out IE10+ with class names
}
.bs-glyphicons li:hover {
  color: #fff;
  background-color: #563d7c;
}

@media (min-width: 768px) {
  .bs-glyphicons {
    margin-right: 0;
    margin-left: 0;
  }
  .bs-glyphicons li {
    width: 12.5%;
    font-size: 12px;
  }
}
