.bs-docs-featurette {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.bs-docs-featurette + .bs-docs-footer {
  margin-top: 0;
  border-top: 0;
}

.bs-docs-featurette-title {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 400;
  color: #333;
}
.half-rule {
  width: 100px;
  margin: 40px auto;
}
.bs-docs-featurette h3 {
  margin-bottom: 5px;
  font-weight: 400;
  color: #333;
}
.bs-docs-featurette-img {
  display: block;
  margin-bottom: 20px;
  color: #333;
}
.bs-docs-featurette-img:hover {
  color: #337ab7;
  text-decoration: none;
}
.bs-docs-featurette-img img {
  display: block;
  margin-bottom: 15px;
}

@media (min-width: @screen-xs-min) {
  .bs-docs-featurette .img-responsive {
    margin-top: 30px;
  }
}
@media (min-width: @screen-sm-min) {
  .bs-docs-featurette {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .bs-docs-featurette-title {
    font-size: 40px;
  }
  .bs-docs-featurette .lead {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .bs-docs-featurette .img-responsive {
    margin-top: 0;
  }
}
