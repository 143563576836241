// stylelint-disable selector-no-qualifying-type, declaration-no-important

// Responsive (scrollable) doc tables
.table-responsive .highlight pre {
  white-space: normal;
}

// Utility classes table
.bs-table th small,
.responsive-utilities th small {
  display: block;
  font-weight: 400;
  color: #999;
}
.responsive-utilities tbody th {
  font-weight: 400;
}
.responsive-utilities td {
  text-align: center;
}
.responsive-utilities td.is-visible {
  color: #468847;
  background-color: #dff0d8 !important;
}
.responsive-utilities td.is-hidden {
  color: #ccc;
  background-color: #f9f9f9 !important;
}

// Responsive tests
.responsive-utilities-test {
  margin-top: 5px;
}
.responsive-utilities-test .col-xs-6 {
  margin-bottom: 10px;
}
.responsive-utilities-test span {
  display: block;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  border-radius: 4px;
}
.visible-on .col-xs-6 .hidden-xs,
.visible-on .col-xs-6 .hidden-sm,
.visible-on .col-xs-6 .hidden-md,
.visible-on .col-xs-6 .hidden-lg,
.hidden-on .col-xs-6 .hidden-xs,
.hidden-on .col-xs-6 .hidden-sm,
.hidden-on .col-xs-6 .hidden-md,
.hidden-on .col-xs-6 .hidden-lg {
  color: #999;
  border: 1px solid #ddd;
}
.visible-on .col-xs-6 .visible-xs-block,
.visible-on .col-xs-6 .visible-sm-block,
.visible-on .col-xs-6 .visible-md-block,
.visible-on .col-xs-6 .visible-lg-block,
.hidden-on .col-xs-6 .visible-xs-block,
.hidden-on .col-xs-6 .visible-sm-block,
.hidden-on .col-xs-6 .visible-md-block,
.hidden-on .col-xs-6 .visible-lg-block {
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
}
