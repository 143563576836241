// stylelint-disable selector-max-id

#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #6f5499;
  outline: 0;
}

#skippy .skiplink-text {
  padding: .5em;
  outline: 1px dotted;
}

#content:focus {
  outline: none;
}
