.bs-team .team-member {
  line-height: 32px;
  color: #555;
}
.bs-team .team-member:hover {
  color: #333;
  text-decoration: none;
}
.bs-team .github-btn {
  float: right;
  width: 180px;
  height: 20px;
  margin-top: 6px;
  border: none;
}
.bs-team img {
  float: left;
  width: 32px;
  margin-right: 10px;
  border-radius: 4px;
}
