.bs-docs-booticon {
  display: block;
  font-weight: 500;
  color: #fff;
  text-align: center;
  cursor: default;
  background-color: #563d7c;
  border-radius: 15%;
}

.bs-docs-booticon-sm {
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 28px;
}

.bs-docs-booticon-lg {
  width: 144px;
  height: 144px;
  font-size: 108px;
  line-height: 140px;
}

.bs-docs-booticon-inverse {
  color: #563d7c;
  background-color: #fff;
}

.bs-docs-booticon-outline {
  background-color: transparent;
  border: 1px solid #cdbfe3;
}
