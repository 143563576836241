// stylelint-disable selector-max-id, declaration-no-important

#carbonads {
  display: block;
  padding: 15px 15px 15px 160px;
  margin: 50px -15px -30px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  border: solid #866ab3;
  border-width: 1px 0 0;

  a {
    color: #fff;
    text-decoration: none;
  }

  @media (min-width: @screen-sm-min) {
    max-width: 330px;
    margin: 50px auto 0;
    border-width: 1px;
    border-radius: 4px;
  }

  @media (min-width: @screen-md-min) {
    position: absolute;
    top: 0;
    right: 15px;
    margin-top: 0;

    .bs-docs-masthead & {
      position: static;
    }
  }
}

.carbon-img {
  float: left;
  margin-left: -145px;
}

.carbon-poweredby {
  display: block;
  color: #cdbfe3 !important;
}
