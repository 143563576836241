.color-swatches {
  margin: 0 -5px;
  overflow: hidden; /* clearfix */
}
.color-swatch {
  float: left;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  border-radius: 3px;
}

@media (min-width: 768px) {
  .color-swatch {
    width: 100px;
    height: 100px;
  }
}

// Framework colors
.color-swatches .gray-darker {
  background-color: #222;
}
.color-swatches .gray-dark {
  background-color: #333;
}
.color-swatches .gray {
  background-color: #555;
}
.color-swatches .gray-light {
  background-color: #999;
}
.color-swatches .gray-lighter {
  background-color: #eee;
}
.color-swatches .brand-primary {
  background-color: #337ab7;
}
.color-swatches .brand-success {
  background-color: #5cb85c;
}
.color-swatches .brand-warning {
  background-color: #f0ad4e;
}
.color-swatches .brand-danger {
  background-color: #d9534f;
}
.color-swatches .brand-info {
  background-color: #5bc0de;
}

// Docs colors
.color-swatches .bs-purple {
  background-color: #563d7c;
}
.color-swatches .bs-purple-light {
  background-color: #c7bfd3;
}
.color-swatches .bs-purple-lighter {
  background-color: #e5e1ea;
}
.color-swatches .bs-gray {
  background-color: #f9f9f9;
}
