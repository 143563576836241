/*!
 * Bootstrap Docs (https://getbootstrap.com/)
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under the Creative Commons Attribution 3.0 Unported License. For
 * details, see https://creativecommons.org/licenses/by/3.0/.
 */
.hll {
  background-color: #ffc;
}
.c {
  color: #999;
}
.err {
  color: #a00;
  background-color: #faa;
}
.k {
  color: #069;
}
.o {
  color: #555;
}
.cm {
  color: #999;
}
.cp {
  color: #099;
}
.c1 {
  color: #999;
}
.cs {
  color: #999;
}
.gd {
  background-color: #fcc;
  border: 1px solid #c00;
}
.ge {
  font-style: italic;
}
.gr {
  color: #f00;
}
.gh {
  color: #030;
}
.gi {
  background-color: #cfc;
  border: 1px solid #0c0;
}
.go {
  color: #aaa;
}
.gp {
  color: #009;
}
.gu {
  color: #030;
}
.gt {
  color: #9c6;
}
.kc {
  color: #069;
}
.kd {
  color: #069;
}
.kn {
  color: #069;
}
.kp {
  color: #069;
}
.kr {
  color: #069;
}
.kt {
  color: #078;
}
.m {
  color: #f60;
}
.s {
  color: #d44950;
}
.na {
  color: #4f9fcf;
}
.nb {
  color: #366;
}
.nc {
  color: #0a8;
}
.no {
  color: #360;
}
.nd {
  color: #99f;
}
.ni {
  color: #999;
}
.ne {
  color: #c00;
}
.nf {
  color: #c0f;
}
.nl {
  color: #99f;
}
.nn {
  color: #0cf;
}
.nt {
  color: #2f6f9f;
}
.nv {
  color: #033;
}
.ow {
  color: #000;
}
.w {
  color: #bbb;
}
.mf {
  color: #f60;
}
.mh {
  color: #f60;
}
.mi {
  color: #f60;
}
.mo {
  color: #f60;
}
.sb {
  color: #c30;
}
.sc {
  color: #c30;
}
.sd {
  font-style: italic;
  color: #c30;
}
.s2 {
  color: #c30;
}
.se {
  color: #c30;
}
.sh {
  color: #c30;
}
.si {
  color: #a00;
}
.sx {
  color: #c30;
}
.sr {
  color: #3aa;
}
.s1 {
  color: #c30;
}
.ss {
  color: #fc3;
}
.bp {
  color: #366;
}
.vc {
  color: #033;
}
.vg {
  color: #033;
}
.vi {
  color: #033;
}
.il {
  color: #f60;
}
.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}
.highlight {
  padding: 9px 14px;
  margin-bottom: 14px;
  background-color: #f7f7f9;
  border: 1px solid #e1e1e8;
  border-radius: 4px;
}
.highlight pre {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  word-break: normal;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.highlight pre code {
  font-size: inherit;
  color: #333;
}
.highlight pre code:first-child {
  display: inline-block;
  padding-right: 45px;
}
.language-bash:before {
  color: #033;
  content: "$ ";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@-ms-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
.btn-outline {
  color: #563d7c;
  background-color: transparent;
  border-color: #563d7c;
}
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  color: #fff;
  background-color: #563d7c;
  border-color: #563d7c;
}
.btn-outline-inverse {
  color: #fff;
  background-color: transparent;
  border-color: #cdbfe3;
}
.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse:active {
  color: #563d7c;
  text-shadow: none;
  background-color: #fff;
  border-color: #fff;
}
.bs-docs-booticon {
  display: block;
  font-weight: 500;
  color: #fff;
  text-align: center;
  cursor: default;
  background-color: #563d7c;
  border-radius: 15%;
}
.bs-docs-booticon-sm {
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 28px;
}
.bs-docs-booticon-lg {
  width: 144px;
  height: 144px;
  font-size: 108px;
  line-height: 140px;
}
.bs-docs-booticon-inverse {
  color: #563d7c;
  background-color: #fff;
}
.bs-docs-booticon-outline {
  background-color: transparent;
  border: 1px solid #cdbfe3;
}
#skippy {
  display: block;
  padding: 1em;
  color: #fff;
  background-color: #6f5499;
  outline: 0;
}
#skippy .skiplink-text {
  padding: 0.5em;
  outline: 1px dotted;
}
#content:focus {
  outline: none;
}
.bs-docs-nav {
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0;
}
.bs-docs-nav .bs-nav-b {
  display: none;
}
.bs-docs-nav .navbar-brand,
.bs-docs-nav .navbar-nav > li > a {
  font-weight: 500;
  color: #563d7c;
}
.bs-docs-nav .navbar-nav > li > a {
  padding-right: 10px;
  padding-left: 10px;
}
.bs-docs-nav .navbar-nav > li > a:hover,
.bs-docs-nav .navbar-nav > .active > a,
.bs-docs-nav .navbar-nav > .active > a:hover {
  color: #463265;
  background-color: #f9f9f9;
}
.bs-docs-nav .navbar-toggle .icon-bar {
  background-color: #563d7c;
}
.bs-docs-nav .navbar-header .navbar-toggle {
  border-color: #fff;
}
.bs-docs-nav .navbar-header .navbar-toggle:hover,
.bs-docs-nav .navbar-header .navbar-toggle:focus {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
}
@media (min-width: 768px) and (max-width: 992px) {
  .bs-docs-nav .navbar-right {
    display: none;
  }
}
.bs-docs-footer {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 100px;
  color: #99979c;
  text-align: center;
  background-color: #2a2730;
}
.bs-docs-footer a {
  color: #fff;
}
.bs-docs-footer-links {
  padding-left: 0;
  margin-bottom: 20px;
}
.bs-docs-footer-links li {
  display: inline-block;
}
.bs-docs-footer-links li + li {
  margin-left: 15px;
}
@media (min-width: 768px) {
  .bs-docs-footer {
    text-align: left;
  }
  .bs-docs-footer p {
    margin-bottom: 0;
  }
}
.bs-docs-masthead,
.bs-docs-header {
  position: relative;
  padding: 30px 0;
  color: #cdbfe3;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
  background-color: #6f5499;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#563d7c), to(#6f5499));
  background-image: -webkit-linear-gradient(top, #563d7c 0%, #6f5499 100%);
  background-image: -o-linear-gradient(top, #563d7c 0%, #6f5499 100%);
  background-image: linear-gradient(to bottom, #563d7c 0%, #6f5499 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#563d7c", endColorstr="#6F5499", GradientType=0);
  background-repeat: repeat-x;
}
.bs-docs-masthead .bs-docs-booticon {
  margin: 0 auto 30px;
}
.bs-docs-masthead h1 {
  font-weight: 300;
  line-height: 1;
  color: #fff;
}
.bs-docs-masthead .lead {
  margin: 0 auto 30px;
  font-size: 20px;
  color: #fff;
}
.bs-docs-masthead .version {
  margin-top: -15px;
  margin-bottom: 30px;
  color: #9783b9;
}
.bs-docs-masthead .btn {
  width: 100%;
  padding: 15px 30px;
  font-size: 20px;
}
@media (min-width: 480px) {
  .bs-docs-masthead .btn {
    width: auto;
  }
}
@media (min-width: 768px) {
  .bs-docs-masthead {
    padding: 80px 0;
  }
  .bs-docs-masthead h1 {
    font-size: 60px;
  }
  .bs-docs-masthead .lead {
    font-size: 24px;
  }
}
@media (min-width: 992px) {
  .bs-docs-masthead .lead {
    width: 80%;
    font-size: 30px;
  }
}
.bs-docs-header {
  margin-bottom: 40px;
  font-size: 20px;
}
.bs-docs-header h1 {
  margin-top: 0;
  color: #fff;
}
.bs-docs-header p {
  margin-bottom: 0;
  font-weight: 300;
  line-height: 1.4;
}
.bs-docs-header .container {
  position: relative;
}
@media (min-width: 768px) {
  .bs-docs-header {
    padding-top: 60px;
    padding-bottom: 60px;
    font-size: 24px;
    text-align: left;
  }
  .bs-docs-header h1 {
    font-size: 60px;
    line-height: 1;
  }
}
@media (min-width: 992px) {
  .bs-docs-header h1,
  .bs-docs-header p {
    margin-right: 380px;
  }
}
#carbonads {
  display: block;
  padding: 15px 15px 15px 160px;
  margin: 50px -15px -30px;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.5;
  text-align: left;
  border: solid #866ab3;
  border-width: 1px 0 0;
}
#carbonads a {
  color: #fff;
  text-decoration: none;
}
@media (min-width: 768px) {
  #carbonads {
    max-width: 330px;
    margin: 50px auto 0;
    border-width: 1px;
    border-radius: 4px;
  }
}
@media (min-width: 992px) {
  #carbonads {
    position: absolute;
    top: 0;
    right: 15px;
    margin-top: 0;
  }
  .bs-docs-masthead #carbonads {
    position: static;
  }
}
.carbon-img {
  float: left;
  margin-left: -145px;
}
.carbon-poweredby {
  display: block;
  color: #cdbfe3 !important;
}
.bs-docs-featurette {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 16px;
  line-height: 1.5;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}
.bs-docs-featurette + .bs-docs-footer {
  margin-top: 0;
  border-top: 0;
}
.bs-docs-featurette-title {
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 400;
  color: #333;
}
.half-rule {
  width: 100px;
  margin: 40px auto;
}
.bs-docs-featurette h3 {
  margin-bottom: 5px;
  font-weight: 400;
  color: #333;
}
.bs-docs-featurette-img {
  display: block;
  margin-bottom: 20px;
  color: #333;
}
.bs-docs-featurette-img:hover {
  color: #337ab7;
  text-decoration: none;
}
.bs-docs-featurette-img img {
  display: block;
  margin-bottom: 15px;
}
@media (min-width: 480px) {
  .bs-docs-featurette .img-responsive {
    margin-top: 30px;
  }
}
@media (min-width: 768px) {
  .bs-docs-featurette {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .bs-docs-featurette-title {
    font-size: 40px;
  }
  .bs-docs-featurette .lead {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  .bs-docs-featurette .img-responsive {
    margin-top: 0;
  }
}
.bs-docs-featured-sites {
  margin-right: -1px;
  margin-left: -1px;
}
.bs-docs-featured-sites .col-xs-8 {
  padding: 1px;
}
.bs-docs-featured-sites .img-responsive {
  margin-top: 0;
}
@media (min-width: 768px) {
  .bs-docs-featured-sites .col-sm-4:first-child img {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .bs-docs-featured-sites .col-sm-4:last-child img {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
.bs-examples .thumbnail {
  margin-bottom: 10px;
}
.bs-examples h4 {
  margin-bottom: 5px;
}
.bs-examples p {
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .bs-examples {
    margin-right: -10px;
    margin-left: -10px;
  }
  .bs-examples > [class^="col-"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.bs-docs-sidebar.affix {
  position: static;
}
@media (min-width: 768px) {
  .bs-docs-sidebar {
    padding-left: 20px;
  }
}
.bs-docs-search {
  margin-bottom: 20px;
  margin-left: 20px;
}
.bs-docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px;
}
.bs-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #767676;
}
.bs-docs-sidebar .nav > li > a:hover,
.bs-docs-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: #563d7c;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid #563d7c;
}
.bs-docs-sidebar .nav > .active > a,
.bs-docs-sidebar .nav > .active:hover > a,
.bs-docs-sidebar .nav > .active:focus > a {
  padding-left: 18px;
  font-weight: 700;
  color: #563d7c;
  background-color: transparent;
  border-left: 2px solid #563d7c;
}
.bs-docs-sidebar .nav .nav {
  display: none;
  padding-bottom: 10px;
}
.bs-docs-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 12px;
  font-weight: 400;
}
.bs-docs-sidebar .nav .nav > li > a:hover,
.bs-docs-sidebar .nav .nav > li > a:focus {
  padding-left: 29px;
}
.bs-docs-sidebar .nav .nav > .active > a,
.bs-docs-sidebar .nav .nav > .active:hover > a,
.bs-docs-sidebar .nav .nav > .active:focus > a {
  padding-left: 28px;
  font-weight: 500;
}
.back-to-top,
.bs-docs-theme-toggle {
  display: none;
  padding: 4px 10px;
  margin-top: 10px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #999;
}
.back-to-top:hover,
.bs-docs-theme-toggle:hover {
  color: #563d7c;
  text-decoration: none;
}
.bs-docs-theme-toggle {
  margin-top: 0;
}
@media (min-width: 768px) {
  .back-to-top,
  .bs-docs-theme-toggle {
    display: block;
  }
}
@media (min-width: 992px) {
  .bs-docs-sidebar .nav > .active > ul {
    display: block;
  }
  .bs-docs-sidebar.affix,
  .bs-docs-sidebar.affix-bottom {
    width: 213px;
  }
  .bs-docs-sidebar.affix {
    position: fixed;
    top: 20px;
  }
  .bs-docs-sidebar.affix-bottom {
    position: absolute;
  }
  .bs-docs-sidebar.affix-bottom .bs-docs-sidenav,
  .bs-docs-sidebar.affix .bs-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .bs-docs-sidebar.affix-bottom,
  .bs-docs-sidebar.affix {
    width: 263px;
  }
}
.show-grid {
  margin-bottom: 15px;
}
.show-grid [class^="col-"] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eee;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid #ddd;
  border: 1px solid rgba(86, 61, 124, 0.2);
}
.bs-example {
  position: relative;
  padding: 45px 15px 15px;
  margin: 0 -15px 15px;
  border-color: #e5e5e5 #eee #eee;
  border-style: solid;
  border-width: 1px 0;
  -webkit-box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.05);
}
.bs-example:after {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: 700;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
  content: "Example";
}
.bs-example-padded-bottom {
  padding-bottom: 24px;
}
.bs-example + .highlight,
.bs-example + .bs-clipboard + .highlight {
  margin: -15px -15px 15px;
  border-width: 0 0 1px;
  border-radius: 0;
}
@media (min-width: 768px) {
  .bs-example {
    margin-right: 0;
    margin-left: 0;
    background-color: #fff;
    border-color: #ddd;
    border-width: 1px;
    border-radius: 4px 4px 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .bs-example + .highlight,
  .bs-example + .bs-clipboard + .highlight {
    margin-top: -16px;
    margin-right: 0;
    margin-left: 0;
    border-width: 1px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .bs-example + .bs-clipboard .btn-clipboard {
    top: -15px;
    border-top-right-radius: 0;
  }
  .bs-example-standalone {
    border-radius: 4px;
  }
}
.bs-example .container {
  width: auto;
}
.bs-example > p:last-child,
.bs-example > ul:last-child,
.bs-example > ol:last-child,
.bs-example > blockquote:last-child,
.bs-example > .form-control:last-child,
.bs-example > .table:last-child,
.bs-example > .navbar:last-child,
.bs-example > .jumbotron:last-child,
.bs-example > .alert:last-child,
.bs-example > .panel:last-child,
.bs-example > .list-group:last-child,
.bs-example > .well:last-child,
.bs-example > .progress:last-child,
.bs-example > .table-responsive:last-child > .table {
  margin-bottom: 0;
}
.bs-example > p > .close {
  float: none;
}
.bs-example-type .table .type-info {
  color: #767676;
  vertical-align: middle;
}
.bs-example-type .table td {
  padding: 15px 0;
  border-color: #eee;
}
.bs-example-type .table tr:first-child td {
  border-top: 0;
}
.bs-example-type h1,
.bs-example-type h2,
.bs-example-type h3,
.bs-example-type h4,
.bs-example-type h5,
.bs-example-type h6 {
  margin: 0;
}
.bs-example-bg-classes p {
  padding: 15px;
}
.bs-example > .img-circle,
.bs-example > .img-rounded,
.bs-example > .img-thumbnail {
  margin: 5px;
}
.bs-example > .table-responsive > .table {
  background-color: #fff;
}
.bs-example > .btn,
.bs-example > .btn-group {
  margin-top: 5px;
  margin-bottom: 5px;
}
.bs-example > .btn-toolbar + .btn-toolbar {
  margin-top: 10px;
}
.bs-example-control-sizing select,
.bs-example-control-sizing input[type="text"] + input[type="text"] {
  margin-top: 10px;
}
.bs-example-form .input-group {
  margin-bottom: 10px;
}
.bs-example > textarea.form-control {
  resize: vertical;
}
.bs-example > .list-group {
  max-width: 400px;
}
.bs-example .navbar:last-child {
  margin-bottom: 0;
}
.bs-navbar-top-example,
.bs-navbar-bottom-example {
  z-index: 1;
  padding: 0;
  overflow: hidden;
}
.bs-navbar-top-example .navbar-header,
.bs-navbar-bottom-example .navbar-header {
  margin-left: 0;
}
.bs-navbar-top-example .navbar-fixed-top,
.bs-navbar-bottom-example .navbar-fixed-bottom {
  position: relative;
  margin-right: 0;
  margin-left: 0;
}
.bs-navbar-top-example {
  padding-bottom: 45px;
}
.bs-navbar-top-example:after {
  top: auto;
  bottom: 15px;
}
.bs-navbar-top-example .navbar-fixed-top {
  top: -1px;
}
.bs-navbar-bottom-example {
  padding-top: 45px;
}
.bs-navbar-bottom-example .navbar-fixed-bottom {
  bottom: -1px;
}
.bs-navbar-bottom-example .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .bs-navbar-top-example .navbar-fixed-top,
  .bs-navbar-bottom-example .navbar-fixed-bottom {
    position: absolute;
  }
}
.bs-example .pagination {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bs-example > .pager {
  margin-top: 0;
}
.bs-example-modal {
  background-color: #f5f5f5;
}
.bs-example-modal .modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}
.bs-example-modal .modal-dialog {
  left: auto;
  margin-right: auto;
  margin-left: auto;
}
.bs-example > .dropdown > .dropdown-toggle {
  float: left;
}
.bs-example > .dropdown > .dropdown-menu {
  position: static;
  display: block;
  margin-bottom: 5px;
  clear: left;
}
.bs-example-tabs .nav-tabs {
  margin-bottom: 15px;
}
.bs-example-tooltips {
  text-align: center;
}
.bs-example-tooltips > .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
.bs-example-tooltip .tooltip {
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
}
.bs-example-popover {
  padding-bottom: 24px;
  background-color: #f9f9f9;
}
.bs-example-popover .popover {
  position: relative;
  display: block;
  float: left;
  width: 260px;
  margin: 20px;
}
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}
.bs-example > .nav-pills-stacked-example {
  max-width: 300px;
}
#collapseExample .well {
  margin-bottom: 0;
}
#focusedInput {
  border-color: #cccccc;
  border-color: rgba(82, 168, 236, 0.8);
  outline: 0;
  outline: thin dotted \9;
  -webkit-box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
  box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
}
.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}
.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-callout p:last-child {
  margin-bottom: 0;
}
.bs-callout code {
  border-radius: 3px;
}
.bs-callout + .bs-callout {
  margin-top: -5px;
}
.bs-callout-danger {
  border-left-color: #ce4844;
}
.bs-callout-danger h4 {
  color: #ce4844;
}
.bs-callout-warning {
  border-left-color: #aa6708;
}
.bs-callout-warning h4 {
  color: #aa6708;
}
.bs-callout-info {
  border-left-color: #1b809e;
}
.bs-callout-info h4 {
  color: #1b809e;
}
.color-swatches {
  margin: 0 -5px;
  overflow: hidden;
  /* clearfix */
}
.color-swatch {
  float: left;
  width: 60px;
  height: 60px;
  margin: 0 5px;
  border-radius: 3px;
}
@media (min-width: 768px) {
  .color-swatch {
    width: 100px;
    height: 100px;
  }
}
.color-swatches .gray-darker {
  background-color: #222;
}
.color-swatches .gray-dark {
  background-color: #333;
}
.color-swatches .gray {
  background-color: #555;
}
.color-swatches .gray-light {
  background-color: #999;
}
.color-swatches .gray-lighter {
  background-color: #eee;
}
.color-swatches .brand-primary {
  background-color: #337ab7;
}
.color-swatches .brand-success {
  background-color: #5cb85c;
}
.color-swatches .brand-warning {
  background-color: #f0ad4e;
}
.color-swatches .brand-danger {
  background-color: #d9534f;
}
.color-swatches .brand-info {
  background-color: #5bc0de;
}
.color-swatches .bs-purple {
  background-color: #563d7c;
}
.color-swatches .bs-purple-light {
  background-color: #c7bfd3;
}
.color-swatches .bs-purple-lighter {
  background-color: #e5e1ea;
}
.color-swatches .bs-gray {
  background-color: #f9f9f9;
}
.bs-team .team-member {
  line-height: 32px;
  color: #555;
}
.bs-team .team-member:hover {
  color: #333;
  text-decoration: none;
}
.bs-team .github-btn {
  float: right;
  width: 180px;
  height: 20px;
  margin-top: 6px;
  border: none;
}
.bs-team img {
  float: left;
  width: 32px;
  margin-right: 10px;
  border-radius: 4px;
}
.table-responsive .highlight pre {
  white-space: normal;
}
.bs-table th small,
.responsive-utilities th small {
  display: block;
  font-weight: 400;
  color: #999;
}
.responsive-utilities tbody th {
  font-weight: 400;
}
.responsive-utilities td {
  text-align: center;
}
.responsive-utilities td.is-visible {
  color: #468847;
  background-color: #dff0d8 !important;
}
.responsive-utilities td.is-hidden {
  color: #ccc;
  background-color: #f9f9f9 !important;
}
.responsive-utilities-test {
  margin-top: 5px;
}
.responsive-utilities-test .col-xs-6 {
  margin-bottom: 10px;
}
.responsive-utilities-test span {
  display: block;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  text-align: center;
  border-radius: 4px;
}
.visible-on .col-xs-6 .hidden-xs,
.visible-on .col-xs-6 .hidden-sm,
.visible-on .col-xs-6 .hidden-md,
.visible-on .col-xs-6 .hidden-lg,
.hidden-on .col-xs-6 .hidden-xs,
.hidden-on .col-xs-6 .hidden-sm,
.hidden-on .col-xs-6 .hidden-md,
.hidden-on .col-xs-6 .hidden-lg {
  color: #999;
  border: 1px solid #ddd;
}
.visible-on .col-xs-6 .visible-xs-block,
.visible-on .col-xs-6 .visible-sm-block,
.visible-on .col-xs-6 .visible-md-block,
.visible-on .col-xs-6 .visible-lg-block,
.hidden-on .col-xs-6 .visible-xs-block,
.hidden-on .col-xs-6 .visible-sm-block,
.hidden-on .col-xs-6 .visible-md-block,
.hidden-on .col-xs-6 .visible-lg-block {
  color: #468847;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
}
.bs-glyphicons {
  margin: 0 -10px 20px;
  overflow: hidden;
}
.bs-glyphicons-list {
  padding-left: 0;
  list-style: none;
}
.bs-glyphicons li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #fff;
}
.bs-glyphicons .glyphicon {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 24px;
}
.bs-glyphicons .glyphicon-class {
  display: block;
  text-align: center;
  word-wrap: break-word;
}
.bs-glyphicons li:hover {
  color: #fff;
  background-color: #563d7c;
}
@media (min-width: 768px) {
  .bs-glyphicons {
    margin-right: 0;
    margin-left: 0;
  }
  .bs-glyphicons li {
    width: 12.5%;
    font-size: 12px;
  }
}
.bs-customizer .toggle {
  float: right;
  margin-top: 25px;
}
.bs-customizer label {
  margin-top: 10px;
  font-weight: 500;
  color: #555;
}
.bs-customizer h2 {
  padding-top: 30px;
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-customizer h3 {
  margin-bottom: 0;
}
.bs-customizer h4 {
  margin-top: 15px;
  margin-bottom: 0;
}
.bs-customizer .bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.bs-customizer input[type="text"] {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  background-color: #fafafa;
}
.bs-customizer .help-block {
  margin-bottom: 5px;
  font-size: 12px;
}
#less-section label {
  font-weight: 400;
}
.bs-customize-download .btn-outline {
  padding: 20px;
}
.bs-customizer-alert {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  padding: 15px 0;
  color: #fff;
  background-color: #d9534f;
  border-bottom: 1px solid #b94441;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.25);
}
.bs-customizer-alert .close {
  margin-top: -4px;
  font-size: 24px;
}
.bs-customizer-alert p {
  margin-bottom: 0;
}
.bs-customizer-alert .glyphicon {
  margin-right: 5px;
}
.bs-customizer-alert pre {
  margin: 10px 0 0;
  color: #fff;
  background-color: #a83c3a;
  border-color: #973634;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(255, 255, 255, 0.1);
}
.bs-dropzone {
  position: relative;
  padding: 20px;
  margin-bottom: 20px;
  color: #777;
  text-align: center;
  border: 2px dashed #eee;
  border-radius: 4px;
}
.bs-dropzone .import-header {
  margin-bottom: 5px;
}
.bs-dropzone .glyphicon-folder-open {
  font-size: 40px;
}
.bs-dropzone hr {
  width: 100px;
}
.bs-dropzone .lead {
  margin-bottom: 10px;
  font-weight: 400;
  color: #333;
}
#import-manual-trigger {
  cursor: pointer;
}
.bs-dropzone p:last-child {
  margin-bottom: 0;
}
.bs-brand-logos {
  display: table;
  width: 100%;
  margin-bottom: 15px;
  overflow: hidden;
  color: #563d7c;
  background-color: #f9f9f9;
  border-radius: 4px;
}
.bs-brand-item {
  padding: 60px 0;
  text-align: center;
}
.bs-brand-item + .bs-brand-item {
  border-top: 1px solid #fff;
}
.bs-brand-logos .inverse {
  color: #fff;
  background-color: #563d7c;
}
.bs-brand-item h1,
.bs-brand-item h3 {
  margin-top: 0;
  margin-bottom: 0;
}
.bs-brand-item .bs-docs-booticon {
  margin-right: auto;
  margin-left: auto;
}
.bs-brand-item .glyphicon {
  width: 30px;
  height: 30px;
  margin: 10px auto -10px;
  line-height: 30px;
  color: #fff;
  border-radius: 50%;
}
.bs-brand-item .glyphicon-ok {
  background-color: #5cb85c;
}
.bs-brand-item .glyphicon-remove {
  background-color: #d9534f;
}
@media (min-width: 768px) {
  .bs-brand-item {
    display: table-cell;
    width: 1%;
  }
  .bs-brand-item + .bs-brand-item {
    border-top: 0;
    border-left: 1px solid #fff;
  }
  .bs-brand-item h1 {
    font-size: 60px;
  }
}
.bs-clipboard {
  position: relative;
  display: none;
  float: right;
}
.bs-clipboard + .highlight {
  margin-top: 0;
}
.btn-clipboard {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 4px 8px;
  font-size: 12px;
  color: #818a91;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.btn-clipboard:hover {
  color: #fff;
  background-color: #027de7;
}
@media (min-width: 768px) {
  .bs-clipboard {
    display: block;
  }
}
.anchorjs-link {
  color: inherit;
}
@media (max-width: 480px) {
  .anchorjs-link {
    display: none;
  }
}
*:hover > .anchorjs-link {
  opacity: 0.75;
  -webkit-transition: color 0.16s linear;
  -o-transition: color 0.16s linear;
  transition: color 0.16s linear;
}
*:hover > .anchorjs-link:hover,
.anchorjs-link:focus {
  text-decoration: none;
  opacity: 1;
}
.algolia-autocomplete {
  display: block !important;
}
.algolia-autocomplete .ds-dropdown-menu {
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  padding: 10px 0 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ddd;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, 0.175);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.175);
}
@media (min-width: 768px) {
  .algolia-autocomplete .ds-dropdown-menu {
    width: 175%;
  }
}
.algolia-autocomplete .ds-dropdown-menu:before {
  display: none !important;
}
.algolia-autocomplete .ds-dropdown-menu [class^="ds-dataset-"] {
  padding: 0 !important;
  overflow: visible !important;
  background-color: transparent !important;
  border: 0 !important;
}
.algolia-autocomplete .ds-dropdown-menu .ds-suggestions {
  margin-top: 0 !important;
}
.algolia-autocomplete .ds-dropdown-menu .ds-input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.algolia-autocomplete .algolia-docsearch-suggestion {
  padding: 0 !important;
  overflow: visible !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--category-header {
  padding: 2px 15px !important;
  margin-top: 0 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #7952b3 !important;
  border-bottom: 0 !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--wrapper {
  float: none !important;
  padding-top: 0 !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--subcategory-column {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
  text-align: left !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--content {
  float: none !important;
  width: auto !important;
  padding: 0 !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--content:before {
  display: none !important;
}
.algolia-autocomplete .ds-suggestion:not(:first-child) .algolia-docsearch-suggestion--category-header {
  padding-top: 10px !important;
  margin-top: 10px !important;
  border-top: 1px solid #eee;
}
.algolia-autocomplete .ds-suggestion .algolia-docsearch-suggestion--subcategory-column {
  display: none !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--title {
  display: block;
  padding: 4px 15px !important;
  margin-bottom: 0 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--text {
  padding: 0 15px 8px !important;
  margin-top: -4px;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.25 !important;
}
.algolia-autocomplete .algolia-docsearch-footer {
  float: none !important;
  width: auto !important;
  height: auto !important;
  padding: 10px 15px 0;
  font-size: 10px !important;
  line-height: 1 !important;
  color: #767676 !important;
  border-top: 1px solid #eee;
}
.algolia-autocomplete .algolia-docsearch-footer--logo {
  display: inline !important;
  overflow: visible !important;
  color: inherit !important;
  text-indent: 0 !important;
  background: none !important;
}
.algolia-autocomplete .algolia-docsearch-suggestion--highlight {
  color: #5f2dab;
  background-color: #eee;
}
.algolia-autocomplete .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
  -webkit-box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
  box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, 0.5) !important;
}
.algolia-autocomplete .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
  background-color: #e5e5e5 !important;
}
body {
  position: relative;
}
.table code {
  font-size: 13px;
  font-weight: 400;
}
h2 code,
h3 code,
h4 code {
  background-color: inherit;
}
.bs-docs-section {
  margin-bottom: 60px;
}
.bs-docs-section:last-child {
  margin-bottom: 0;
}
h1[id] {
  padding-top: 20px;
  margin-top: 0;
}
.bs-docs-browser-bugs td p {
  margin-bottom: 0;
}
.bs-docs-browser-bugs th:first-child {
  width: 18%;
}
.bs-events-table > thead > tr > th:first-child,
.bs-events-table > tbody > tr > td:first-child {
  white-space: nowrap;
}
.bs-events-table > thead > tr > th:first-child {
  width: 150px;
}
.js-options-table > thead > tr > th:nth-child(1),
.js-options-table > thead > tr > th:nth-child(2) {
  width: 100px;
}
.js-options-table > thead > tr > th:nth-child(3) {
  width: 50px;
}
.v4-tease {
  display: block;
  padding: 15px 20px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  background-color: #0275d8;
}
.v4-tease:focus,
.v4-tease:hover {
  color: #fff;
  text-decoration: none;
  background-color: #0269c2;
}
/* Nullify ill-advised printing of hrefs; see #18711 */
@media print {
  a[href]:after {
    content: "" !important;
  }
}
/*# sourceMappingURL=docs.css.map */