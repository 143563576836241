// Outline button for use within the docs
.btn-outline {
  color: #563d7c;
  background-color: transparent;
  border-color: #563d7c;

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    background-color: #563d7c;
    border-color: #563d7c;
  }
}

// Inverted outline button (white on dark)
.btn-outline-inverse {
  color: #fff;
  background-color: transparent;
  border-color: #cdbfe3;

  &:hover,
  &:focus,
  &:active {
    color: #563d7c;
    text-shadow: none;
    background-color: #fff;
    border-color: #fff;
  }
}
