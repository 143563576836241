.bs-docs-featured-sites {
  margin-right: -1px;
  margin-left: -1px;
}
.bs-docs-featured-sites .col-xs-8 {
  padding: 1px;
}
.bs-docs-featured-sites .img-responsive {
  margin-top: 0;
}

@media (min-width: 768px) {
  .bs-docs-featured-sites .col-sm-4:first-child img {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .bs-docs-featured-sites .col-sm-4:last-child img {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
