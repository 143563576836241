// stylelint-disable at-rule-no-vendor-prefix

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// See the Getting Started docs for more information:
// https://getbootstrap.com/docs/3.4/getting-started/#support-ie10-width

@-ms-viewport     { width: device-width; }
@-o-viewport      { width: device-width; }
@viewport         { width: device-width; }
