// stylelint-disable declaration-no-important

// Docsearch overrides
//
// `!important` indicates overridden properties.
.algolia-autocomplete {
  display: block !important;

  // Menu container
  .ds-dropdown-menu {
    width: 100%;
    min-width: 0 !important;
    max-width: none !important;
    padding: 10px 0 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ddd;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 8px 15px rgba(0, 0, 0, .175);

    @media (min-width: @screen-sm-min) {
      width: 175%;
    }

    // Caret
    &:before {
      display: none !important;
    }

    [class^="ds-dataset-"] {
      padding: 0 !important;
      overflow: visible !important;
      background-color: transparent !important;
      border: 0 !important;
    }

    .ds-suggestions {
      margin-top: 0 !important;
    }

    .ds-input {
      box-shadow: none;
    }
  }

  .algolia-docsearch-suggestion {
    padding: 0 !important;
    overflow: visible !important;
  }

  .algolia-docsearch-suggestion--category-header {
    padding: 2px 15px !important;
    margin-top: 0 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    color: #7952b3 !important;
    border-bottom: 0 !important;
  }

  .algolia-docsearch-suggestion--wrapper {
    float: none !important;
    padding-top: 0 !important;
  }

  // Section header
  .algolia-docsearch-suggestion--subcategory-column {
    float: none !important;
    width: auto !important;
    padding: 0 !important;
    text-align: left !important;
  }

  .algolia-docsearch-suggestion--content {
    float: none !important;
    width: auto !important;
    padding: 0 !important;

    // Vertical divider between column header and content
    &:before {
      display: none !important;
    }
  }

  .ds-suggestion {
    &:not(:first-child) {
      .algolia-docsearch-suggestion--category-header {
        padding-top: 10px !important;
        margin-top: 10px !important;
        border-top: 1px solid #eee;
      }
    }

    .algolia-docsearch-suggestion--subcategory-column {
      display: none !important;
    }
  }

  .algolia-docsearch-suggestion--title {
    display: block;
    padding: 4px 15px !important;
    margin-bottom: 0 !important;
    font-size: 13px !important;
    font-weight: 400 !important;
  }

  .algolia-docsearch-suggestion--text {
    padding: 0 15px 8px !important;
    margin-top: -4px;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 1.25 !important;
  }

  .algolia-docsearch-footer {
    float: none !important;
    width: auto !important;
    height: auto !important;
    padding: 10px 15px 0;
    font-size: 10px !important;
    line-height: 1 !important;
    color: #767676 !important;
    border-top: 1px solid #eee;
  }

  .algolia-docsearch-footer--logo {
    display: inline !important;
    overflow: visible !important;
    color: inherit !important;
    text-indent: 0 !important;
    background: none !important;
  }

  .algolia-docsearch-suggestion--highlight {
    color: #5f2dab;
    background-color: #eee;
  }

  .algolia-docsearch-suggestion--text .algolia-docsearch-suggestion--highlight {
    box-shadow: inset 0 -2px 0 0 rgba(95, 45, 171, .5) !important;
  }

  .ds-suggestion.ds-cursor .algolia-docsearch-suggestion--content {
    background-color: #e5e5e5 !important;
  }
}
