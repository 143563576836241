// stylelint-disable value-no-vendor-prefix, function-name-case

.bs-docs-masthead,
.bs-docs-header {
  position: relative;
  padding: 30px 0;
  color: #cdbfe3;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .1);
  background-color: #6f5499;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#563d7c), to(#6f5499));
  background-image: -webkit-linear-gradient(top, #563d7c 0%, #6f5499 100%);
  background-image: -o-linear-gradient(top, #563d7c 0%, #6f5499 100%);
  background-image: linear-gradient(to bottom, #563d7c 0%, #6f5499 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#563d7c", endColorstr="#6F5499", GradientType=0);
  background-repeat: repeat-x;
}

// Masthead (headings and download button)
.bs-docs-masthead .bs-docs-booticon {
  margin: 0 auto 30px;
}
.bs-docs-masthead h1 {
  font-weight: 300;
  line-height: 1;
  color: #fff;
}
.bs-docs-masthead .lead {
  margin: 0 auto 30px;
  font-size: 20px;
  color: #fff;
}
.bs-docs-masthead .version {
  margin-top: -15px;
  margin-bottom: 30px;
  color: #9783b9;
}
.bs-docs-masthead .btn {
  width: 100%;
  padding: 15px 30px;
  font-size: 20px;
}

@media (min-width: @screen-xs-min) {
  .bs-docs-masthead .btn {
    width: auto;
  }
}

@media (min-width: @screen-sm-min) {
  .bs-docs-masthead {
    padding: 80px 0;
  }
  .bs-docs-masthead h1 {
    font-size: 60px;
  }
  .bs-docs-masthead .lead {
    font-size: 24px;
  }
}

@media (min-width: @screen-md-min) {
  .bs-docs-masthead .lead {
    width: 80%;
    font-size: 30px;
  }
}
